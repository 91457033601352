.hiw-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*background-color: #7a40ee;
    background-image: linear-gradient(135deg, #5546ff, rgba(122, 64, 238, 0));*/
    
    background-color: black;
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 80px;
    border-radius: 4rem;
  }
.hiw-title {
    font-size: 32px;
    font-weight: 500;
    color: white;
    margin-bottom: 0;
}
.hiw-subtitle {
    font-size: 20px;
    font-weight: 300;
    color: white;
}
.hiw-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}
.hiw-card {
    height: 100%;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    display: flex;
    position: relative;
    width: 250px;
    min-height: 300px;
    margin: 1.5rem;
    border: 2px solid #7a40ee;
    background-color: #7a40ee;
    background-image: linear-gradient(135deg, #5546ff, rgba(122, 64, 238, 0));
    color: white;
    font-weight: 400;
}
.hiw-card-points {
    font-size: 16pt;
    font-weight: 300;
}
.hiw-card-desc {
    font-size: 20pt;
    
}
.hiw-card-image {
    width: 110px;
    margin-left: 70px;
}